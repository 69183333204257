import CookieConsent from "react-cookie-consent";

export function Cookies() {
  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText="Prihvati"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{
          background: "#ca2026",
          color: "white",
          fontSize: "13px",
          padding: "15px 40px",
          borderRadius: "3px",
        }}
        expires={150}
      >
        Ova web stranica koristi kolačiće kako bismo osigurali najbolje moguće
        iskustvo korisnicima.{" "}
        <a href="/privacypolice" style={{ color: "#ca2026" }}>
          Više informacija
        </a>
      </CookieConsent>
    </div>
  );
}
