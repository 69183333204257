import React from "react";
import { FaInstagram, FaWhatsapp, FaViber } from "react-icons/fa";
import { AiOutlineFacebook } from "react-icons/ai";
import "../../styles/SodicalMedia.scss";

export function SocialMedia() {
  const handleContactClick = (app) => {
    if (app === "viber") {
      window.location.href = "viber://chat?number=+385989029277";
    } else if (app === "whatsapp") {
      window.location.href =
        "https://api.whatsapp.com/send?phone=+385989029277";
    }
  };

  const facebookClick = () => {
    window.open(
      "https://www.facebook.com/profile.php?id=100087110885013",
      "_blank"
    );
  };

  const instagramClick = () => {
    window.open("https://www.instagram.com/robert.logistika/", "_blank");
  };

  return (
    <div className="social-icon">
      <ul className="social-icon__list">
        <li onClick={facebookClick}>
          <AiOutlineFacebook size="25px" color="#FFF" />
        </li>
        <li onClick={instagramClick}>
          <FaInstagram size="25px" color="#FFF" />
        </li>
        <li onClick={() => handleContactClick("whatsapp")}>
          <FaWhatsapp size="25px" color="#FFF" />
        </li>
        <li onClick={() => handleContactClick("viber")}>
          <FaViber size="25px" color="#FFF" />
        </li>
      </ul>
    </div>
  );
}
