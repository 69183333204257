import { Button } from "../ui/Button";
import "../styles/FurnitureService.scss";
import { ScrollAnimation } from "../components/ScrollAnimation/ScrollAnimation";

export function FurnitureService() {

      const handleCallClickMobile2 = () => {
        window.location.href = "tel:+385915151011";
      };

  return (
    <div className="furniture-container">
      <div className="furniture-content">
      <ScrollAnimation>
        <div className="furniture-text">
          <h2 className="furniture-title">RL FURNITURE - Montaža namještaja</h2>
          <ul className="description">
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="icon"
              >
                <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
              </svg>
              <span>UNOS KUPLJENE ROBE</span>
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="icon"
              >
                <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
              </svg>
              <span>MONTAŽA NAMJEŠTAJA</span>
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="icon"
              >
                <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
              </svg>
              <span>DEMONTAŽA I PAKIRANJE</span>
            </li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="icon"
              >
                <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
              </svg>
              <span>TAPECIRANJE NAMJEŠTAJA</span>
            </li>
          </ul>
          <div className="furniture-button">
            <Button type="button" className="btn-contact" name="Kontaktirajte nas" onClick={handleCallClickMobile2}/>
          </div>
        </div>
        </ScrollAnimation>
        <ScrollAnimation>
        <div className="furniture-image">
          <img src="/assets/services/unos.jpg" alt="unos" className="img1" />
          <img
            src="/assets/services/montaza.jpg"
            alt="montaza"
            className="img2"
          />
          <img
            src="/assets/services/demontaza.jpg"
            alt="demontaza"
            className="img3"
          />
        </div>
        </ScrollAnimation>
      </div>
    </div>
  );
}
