export const navItems = [
  {
    title: "Naslovna",
    id: "home",
  },
  {
    title: "Statistika",
    id: "about",
  },
  {
    title: "Usluge",
    id: "service",
  },
  {
    title: "Partneri",
    id: "partners",
  },
];
