import React, { useEffect, useState } from "react";
import "../../styles/AnimateText.scss";

export function AnimateText() {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const text = "LOGISTIKA";
    let i = currentIndex;

    const interval = setInterval(() => {
      const title = document.getElementById("animated-title_text");

      if (i === 0) {
        title.innerHTML = "";
      } else {
        title.innerHTML = text.substring(0, i);
      }

      i++;

      if (i > text.length) {
        clearInterval(interval);

        if (currentIndex === text.length) {
          setTimeout(() => {
            setCurrentIndex(0);
          }, 500);
        }
      } else {
        setCurrentIndex(i);
      }
    }, 400);

    return () => clearInterval(interval);
  }, [currentIndex]);

  return (
    <div className="main">
      <div className="title">
        <h1 className="title-text">ROBERT</h1>
      </div>
      <div className="animated-title">
        <span id="animated-title_text"></span>
      </div>
    </div>
  );
}
