import React, { useEffect, useRef, useState } from 'react';
import '../../styles/ScrollAnimation.scss';

export function ScrollAnimation({ children }) {
  const [inView, setInView] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const currentRef = ref.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setInView(true);
          if (currentRef) {
            observer.unobserve(currentRef);
          }
        }
      },
      {
        threshold: 0.1,
      }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [ref]);

  return (
    <div className={`animated-section ${inView ? 'in-view' : ''}`} ref={ref}>
      {children}
    </div>
  );
};

export default ScrollAnimation;
