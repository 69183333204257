import { HashLink as Link } from "react-router-hash-link";
import { motion } from "framer-motion";
import { menuSlide } from "../../ui/Anime/anime";
import { navItems } from "../../Services/navItems";
import { Curve } from "../../ui/Curve/Curve";
import { SocialMedia } from "../../components/SocialMedia/SocialMedia";
import "../../styles/Nav.scss";

export function Nav({ closeNav }) {
  return (
    <motion.div
      variants={menuSlide}
      initial="initial"
      animate="enter"
      exit="exit"
      className="menu"
    >
      <div className="body">
        <div className="nav">
          <div className="nav-header">
            <p>ROBERT Logistika</p>
          </div>
          {navItems.map((data, index) => (
            <Link key={index} smooth to={`#${data.id}`} onClick={closeNav}>
              {data.title}
            </Link>
          ))}
        </div>
        <SocialMedia />
      </div>
      <Curve />
    </motion.div>
  );
}
