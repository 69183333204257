export const partners =[
    {
      id: 1,
      img: "assets/partners/formis.png",
      title: "FORMIS FURNITURE",
    },
    {
      id: 2,
      img: "assets/partners/prima.png",
      title: "PRIMA COMMERCE",
    },
    {
      id: 3,
      img: "assets/partners/bakula-lajsne.png",
      title: "BAKULA - Laminati i lajsne Osijek",
    },
    {
      id: 4,
      img: "assets/partners/mobel-land.png",
      title: "MÖBEL LAND GMBH",
    },
    {
      id: 5,
      img: "assets/partners/pozgaj-grupa.png",
      title: "POŽGAJ grupa",
    },
    {
      id: 6,
      img: "assets/partners/pro-rez.png",
      title: "PRO REZ",
    },
    {
      id: 7,
      img: "assets/partners/tepih-centar.png",
      title: "TEPIH CENTAR",
    },
    {
      id: 8,
      img: "assets/partners/terra-livestock.png",
      title: "TERRALIVESTOCK",
    },
]
  