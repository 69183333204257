import { Footer } from "../layouts/Footer/Footer";
import { Header } from "../layouts/Header/Header";
import "../styles/TermsAndConditions.scss";

export function TermsAndConditions() {
  return (
    <div className="container">
      <Header />
      <div className="terms-content">
        <h1>ODREDBE I UVIJETI</h1>
        <div>
          <p>Sve što kažemo, sve što radimo. Samo najbolje za Vas.</p>
          <p>
            Sljedeći Uvjeti poslovanja vrijede za sve usluge tvrtke Robert
            Logistika j.d.o.o. Rezervacijom putovanja, transfera, najma ili neke
            druge usluge potvrđujete razumijevanje i suglasnost s ovim Uvjetima.
          </p>
        </div>
        <div>
          <h4>PREDMET OPĆIH UVJETA POSLOVANJA</h4>
          <p className="data_police">
            Ovi Opći uvjeti poslovanja (dalje u tekstu: „Opći uvjeti
            poslovanja“) predstavljaju uvjete poslovanja trgovačkog društva
            ROBERT LOGISTIKA j.d.o.o. Omladinska ul. 119, HR-31431 Livana, (u
            daljnjem tekstu: ROBERT LOGISTIKA j.d.o.o.) kojima se reguliraju
            međusobna prava i obveze ROBERT LOGISTIKE j.d.o.o. s jedne strane,
            te korisnika usluga (stranke, korisnika, naručitelja, davatelja
            naloga, u daljnjem tekstu skupno i pojedinačno: „Korisnik“), s druge
            strane.
          </p>
          <p>
            Ovi se Opći uvjeti poslovanja odnose na usluge koje pruža ROBERT
            LOGISTIKA j.d.o.o., i to:
          </p>
          <ul>
            <li>
              poslovi posredovanja prilikom zapošljavanja prijevoznih kapaciteta
              prijevoznika u prijevozu tereta;
            </li>
            <li>
              poslovi pomaganja Korisniku prilikom izvršenja primljenog naloga,
              te posredovanja između Korisnika i drugih sudionika predmetnog
              pravnog posla;
            </li>
            <li>
              poslovima posredovanja prilikom rješavanja sporova u svezi poslova
              u kojima posreduju;
            </li>
            <li>druge ovdje neimenovane usluge.</li>
          </ul>
        </div>

        <div>
          <h4>PRIMJENA OPĆIH UVJETA POSLOVANJA</h4>
          <p className="data_police">
            Ovi Opći uvjeti poslovanja sastavni su dio svih zasebnih ugovora
            koje ROBERT LOGISTIKA j.d.o.o. sklapa s Korisnikom. Ako je neka
            odredba iz ovih Općih uvjeta poslovanja u suprotnosti s pisanim
            odredbama zasebnog Ugovora, primijenit će se odredba iz zasebnog
            Ugovora.
          </p>
          <p className="data_police">
            Sastavni dio svakog pojedinog zasebnog Ugovora kojeg ROBERT
            LOGISTIKA j.d.o.o. sklapa s Korisnikom, osim ovih Općih uvjeta
            poslovanja, mogu biti: Ponuda, Nalog.
          </p>
          <p className="data_police">
            Svrha ovih Općih uvjeta je utvrditi uvjete pod kojim ROBERT
            LOGISTIKA j.d.o.o., pruža svoje usluge logistike i / ili usluge
            prijevoza robe u domaćem i međunarodnom prijevozu kada se pojavljuje
            u bilo kojem svojstvu (špediter, držatelj skladišta, agent, agent
            koji pruža carinsko posredovanje, otpremnik, prijevoznik, itd.) za
            Korisnika.
          </p>
          <p className="data_police">
            Bilo koji Ugovor ili radnja ROBERT LOGISTIKA j.d.o.o. podrazumijeva
            prihvaćanje ovih Općih uvjeta poslovanja bez ikakvih rezervi i
            ograničenja, od strane Korisnika.
          </p>
        </div>

        <div>
          <h4>KORISNIK</h4>
          <p className="data_police">
            Za potrebe ovih Općih uvjeta poslovanja pojam Korisnik predstavlja
            poslovno sposobnu fizičku ili pravnu osobu koja je naručila usluge
            koje pruža ROBERT LOGISTIKA j.d.o.o.
          </p>
        </div>

        <div>
          <h4>POŠILJATELJ</h4>
          <p className="data_police">
            Za potrebe ovih Općih uvjeta poslovanja pošiljatelj je osoba koja
            šalje robu primatelju.
          </p>
        </div>

        <div>
          <h4>PRIMATELJ</h4>
          <p className="data_police">
            Za potrebe ovih Općih uvjeta poslovanja primatelj je osoba koja
            prima robu na adresu koju je naznačio Korisnik ili Pošiljatelj.
          </p>
        </div>

        <div>
          <h4>PRIJEVOZNIK</h4>
          <p className="data_police">
            Za potrebe ovih Općih uvjeta poslovanja Prijevoznik je poslovno
            sposobna fizička ili pravna osoba koja stvarno obavlja uslugu
            prijevoza ili dio usluga prijevoza.
          </p>
        </div>

        <div>
          <h4>DOSTAVA</h4>
          <p className="data_police">
            Za potrebe ovih Općih uvjeta poslovanja Dostava se smatra izvršenom
            kada se roba isporuči na adresu Primatelja koju je naznačio Korisnik
            ili Pošiljatelj.
          </p>
        </div>

        <div>
          <h4>ROBA</h4>
          <p className="data_police">
            Za potrebe ovih Općih uvjeta poslovanja Roba predstavlja predmet ili
            materijalni entitet koji se sastoji od nekoliko predmeta, bez obzira
            na njegovu težinu, dimenzije ili zapreminu, tvoreći jedinični teret
            kada je specificiran za (kanta, kavez, kofer, karton, kontejner,
            teret, vezana paleta ili umotana u pakiranje od strane naručitelja,
            rola itd.), upakiran od strane pošiljatelja prije transporta.
          </p>
        </div>

        <div>
          <h4>ZAHTJEV</h4>
          <p className="data_police">
            Za potrebe ovih Općih uvjeta poslovanja Zahtjev je svaki usmeni,
            pisani ili elektroničkim putem postavljeni zahtjev odnosno upit za
            davanje Ponude.
          </p>
          <p className="data_police">
            Za potrebe izrade Ponude podnositelj Zahtjeva, odnosno Korisnik dat
            će na uvid ROBERT LOGISTIKA j.d.o.o. ili od ROBERT LOGISTIKA
            j.d.o.o. ovlaštenoj osobi, svoje podatke potrebne za izradu Ponude
            koji će podaci kasnije služiti za eventualno sklapanje Ugovora.
          </p>
        </div>

        <div>
          <h4>PONUDA</h4>
          <p className="data_police">
            Ponuda je pisani odgovor društva ROBERT LOGISTIKA j.d.o.o. upućen
            Korisniku na njegov Zahtjev, a koji sadrži pobliži opis usluge koju
            bi ROBERT LOGISTIKA j.d.o.o. pružila Korisniku, te sadrži cijenu ili
            način na koji se cijena određuje, uz pozivanje na ove Opće uvjete
            poslovanja. Ponuda je ujedno prijedlog za sklapanje Ugovora učinjen
            Korisniku. Ponuda obuhvaća samo poslove koji su u njoj izričito
            navedeni.
          </p>
          <p className="data_police">
            Ponuda je ograničena saznanjima koja su ROBERT LOGISTICI j.d.o.o. u
            vrijeme davanja Ponude bila poznata. ROBERT LOGISTIKA j.d.o.o. može
            odbiti izdavanje Ponude, odnosno nije dužna odgovoriti na zahtjev za
            izdavanjem Ponude, po vlastitom nahođenju.
          </p>
          <p className="data_police">
            Ponuda veže ROBERT LOGISTIKU j.d.o.o. ako je izdana u pisanom obliku, i
            to sedam dana od njena izdavanja, te po proteku sedmog dana od
            datuma navedenog na ponudi, ona ističe, osim ako je u Ponudi
            izrijekom navedeno drugačije.
          </p>
        </div>

        <div>
          <h4>SPECIFIKACIJA USLUGE</h4>
          <p className="data_police">
            Prihvatom Ponude Korisnik pristaje da će se njegova roba otpremati
            zajedno s robom drugih korisnika, osim ako nije drugačije ugovoreno.
          </p>
        </div>

        <div>
          <h4>ROK IZVRŠENJA USLUGE</h4>
          <p className="data_police">
            Rok izvršenja usluge može se utvrditi Ponudom kao broj radnih dana
            potrebnih za konačno izvršenje usluge, odnosno kao fiksni datum, ako
            vrijeme dopusti.
          </p>
          <p className="data_police">
            Ako rok izvršenja usluge ovisi o kakvom uvjetu koji se mora
            ostvariti prije početka pružanja usluge, tada početak računanja
            rokova teče od dana ostvarenja uvjeta.
          </p>
        </div>

        <div>
          <h4>NALOG</h4>
          <p className="data_police">
            Za potrebe ovih Općih uvjeta poslovanja Nalog jest pisana
            korespondencija Korisnika kojom prihvaća Ponudu izdanu od strane TOP
            LOGISTIKE d.o.o., čime naručuje i prihvaća uslugu prema Ponudi i
            ovim Općim uvjetima poslovanja. Nalogom Korisnik ujedno prihvaća i
            obvezuje se platiti cijenu naznačenu u Ponudi. Nalog je prihvat
            Ponude u cijelosti, zajedno sa svim eventualnim dodacima Ponude.
            Djelomični Nalog, odnosno Nalog u kojem se mijenjaju neki od uvjeta
            Ponude smatra se novim Zahtjevom.
          </p>
          <p className="data_police">
            Ako je Nalog dan izvan roka važenja Ponude, potrebno je da bude
            naknadno izrijekom prihvaćen od strane ROBERT LOGISTIKE j.d.o.o. Nalog
            treba sadržavati sve potrebne podatke o robi i njenim svojstvima,
            točne upute u pogledu njihove otpreme i izvršenju poslova u vezi s
            otpremom te druge podatke potrebne za ispravno i pravodobno
            izvršavanje Naloga.
          </p>
          <p className="data_police">
            Korisnik je dužan obavijestiti ROBERT LOGISTIKU j.d.o.o. o svojstvima
            Robe kojima može biti ugrožena sigurnost osoba ili dobara ili kojima
            može biti nanesena šteta, u protivnom je odgovoran za štetu koja
            nastane trećim osobama.
          </p>
          <p className="data_police">
            Korisnik koji je davatelj Naloga snosi sve posljedice nastale zbog
            pogrešnog, nepotpunog, nejasnog, proturječnog i prekasno danog
            Naloga.
          </p>
        </div>

        <div>
          <h4>UGOVOR</h4>
          <p className="data_police">
            Ugovor o pružanju usluge može biti sklopljen pismenim putem,
            neposredno ili na daljinu kao zasebna pisana isprava, putem e-maila,
            telefaksa ili elektronskim putem.
          </p>
          <p className="data_police">
            Ako Ugovor nije sačinjen kao zasebna isprava, tada se Ugovorom
            smatra Nalog, odnosno prihvat ili potpis na Ponudi, pod uvjetom da
            je dan u roku važenja Ponude.
          </p>
        </div>

        <div>
          <h4>USLUGA</h4>
          <p className="data_police">
            Usluga jest posao kojeg obavlja ROBERT LOGISTIKA j.d.o.o. za potrebe
            Korisnika, a koji može obuhvaćati posredovanje prilikom usluge
            prijevoza, skalištenja, carinjenja i drugih usluga u obavljanju
            prijvoza u domaćem i međunarodnom prijevozu.
          </p>
          <p className="data_police">
            ROBERT LOGISTIKA j.d.o.o. pruža Usluge iz svoga djelokruga poslovanja
            sukladno zakonskim i podzakonskim aktima, te ugovornim obvezama.
          </p>
          <p className="data_police">
            ROBERT LOGISTIKA j.d.o.o. se obvezuje usluge iz svoga djelokruga
            poslovanja obavljati sa pažnjom dobrog gospodarstvenika, te s
            pažnjom dobrog gospodarstvenika birati osobe kojima se u svom poslu
            služi.
          </p>
          <p className="data_police">
            ROBERT LOGISTIKA j.d.o.o. u skladu s ovim Općim uvjetima poslovanja
            djeluje kao posrednik u ime i za račun Korisnika, te ima pravo, a
            Korisnik ga ovime izričito ovlašćuje, da u ime Korisnika sklapa
            sporazume s trećim stranama:
          </p>
          <ul>
            <li>za prijevoz robe bilo kojim putem ili sredstvom ili osobom;</li>
            <li>
              za skladištenje, pakiranje, pretovar, istovar ili rukovanje robom
              od bilo koje osobe i na bilo kojem mjestu u bilo koje vrijeme;
            </li>
            <li>
              za prijevoz ili skladištenje bilo koje robe u ili na transportnim
              jedinicama i s drugom robom bilo koje prirode na način koji neće
              ugroziti siguran prijevoz odnosno svojstva robe;
            </li>
            <li>
              čini radnje koje bi prema mišljenju ROBERT LOGISTIKE j.d.o.o.-a mogle
              biti razumno potrebne u izvršavanju obveza prema Korisniku.
            </li>
          </ul>
          <p className="data_police">
            ROBERT LOGISTIKA j.d.o.o. neće snositi nikakvu odgovornost za gubitak ili
            oštećenje Robe kada djeluje kao posrednik za Korisnika, osim ako je
            takav gubitak ili šteta uzrokovana krajnjom nepažnjom ili namjerom
            ROBERT LOGISTIKE j.d.o.o. ROBERT LOGISTIKA j.d.o.o. ni u kojem slučaju neće
            biti odgovorna za kašnjenje u isporuci robe osim u djelu u kojem je
            razlog za kašnjenje isključivo skrivljeno ponašanje TOP LOGISTIKE
            d.o.o.. Šteta zbog gubitka, oštećenja ili zakašnjenja u isporuci
            robe pada na teret Prijevoznika koji vrši prijevoz Robe.
          </p>
        </div>

        <div>
          <h4>CIJENA</h4>
          <p className="data_police">
            Cijena je iznos kojeg je Korisnik dužan platiti ROBERT LOGISTICI j.d.o.o.
            za pruženu uslugu. Cijene se izračunavaju na temelju podataka koje
            pruža Korisnik, uzimajući u obzir posebno Usluge koje treba
            izvršiti, prirodu, težinu i količinu Robe, rute kojima treba ići te
            bilo koji događaj koji bi mogao utjecati na cijenu Usluga. Cijena
            može biti izražena u tzv. lump –sum iznosu, odnosno u fiksnom iznosu
            u skladu s izdanom Ponudom, ili specificirana prema pruženim
            uslugama.
          </p>
          <p className="data_police">
            Cijene isključuju poreze, naknade, namete, carine ili odbitke bilo
            koje prirode („Porezi“) te se Korisnik obvezuje da će snositi i
            platiti bilo koji porez u odnosu na provedbu ovih Općih uvjeta
            poslovanja koji proizlazi izravno ili neizravno iz njegove izvedbe.
          </p>
          <p className="data_police">
            Usluge kod kojih su cijene izražene u eurima, plaćaju se u kunama
            prema srednjem tečaju Hrvatske narodne banke na dan izdavanja
            računa, uvećano za važeću stopu PDV-a.
          </p>
          <p className="data_police">
            ROBERT LOGISTIKA j.d.o.o. i od nje angažirani Prijevoznik imaju pravo na
            ugovorenu Cijenu kada je usluga izvršena.
          </p>
        </div>

        <div>
          <h4>OBAVLJANJE USLUGA</h4>
          <p className="data_police">
            Usluge se izvode u skladu s najboljom lokalnom praksom i, ako se
            drugačije ne dogovori, u uobičajenom radnom vremenu.
          </p>
          <p className="data_police">
            ROBERT LOGISTIKA j.d.o.o. te ima pravo angažirati prijevoznika za
            obavljanje usluge prijevoza.
          </p>
          <p className="data_police">
            Korisnik je odgovoran za pribavljanje i održavanje bilo kakvih
            odobrenja ili potvrda koje se odnose na robu za koju treba
            organizirati uslugu prijevoz, prema zahtjevima regulatornih tijela.
            ROBERT LOGISTIKA j.d.o.o. pružiti će svaku razumnu pomoć koja se može
            zahtijevati u vezi s tim.
          </p>
          <p className="data_police">
            Korisnik će dostaviti ROBERT LOGISTICI j.d.o.o. punomoć koja je uredno
            popunjena i potpisana od strane ovlaštene osobe koja omogućava TOP
            LOGISTICI d.o.o. da izvrši carinjenje u ime i za račun Korisnika.
            ROBERT LOGISTIKA j.d.o.o. će u ime i za račun Korisnika platiti carine i
            poreze koji se odnose na carinske operacije samo ako je iznos carina
            i poreza Korisnik unaprijed isplatio ROBERT LOGISTICI j.d.o.o.
          </p>
          <p className="data_police">
            Od korisnika se očekuje da će na vrijeme dati ROBERT LOGISTICI j.d.o.o.
            precizne upute potrebne za ispunjenje Usluga. ROBERT LOGISTIKA j.d.o.o.
            nije obvezna provjeravati dokumente (komercijalnu fakturu, popis
            pakiranja itd.) koje je dostavio Korisnik. Korisnik se obvezuje dati
            ROBERT LOGISTICI j.d.o.o. karakteristike povjerene robe (sastav i
            klasifikacija robe, vrijednost robe, pakiranje, broj jedinica u
            pakiranju, dimenzije pakiranja, visina i vrsta paleta, težina,
            sigurnosne napomene, ograničenja skladištenja i rukovanja, itd.) i
            odmah obavijestiti ROBERT LOGISTIKU j.d.o.o. o svakoj promjeni ovih
            karakteristika. U slučaju nepotpune ili netočne ili kasne izjave u
            vezi s gore navedenim informacijama, isključivo će Korisnik snositi
            sve nastale izravne i neizravne posljedice i smatrat će se da TOP
            LOGISTIKA d.o.o. nije odgovorna za bilo kakvu štetu, trošak ili
            naknadu bez obzira na visinu iste.
          </p>
        </div>

        <div>
          <h4>PAKIRANJE I OZNAČAVANJE ROBE</h4>
          <p className="data_police">
            Roba mora biti zapakirana i označena na način koji je prikladan za
            transport i / ili postupak skladištenja koji se provodi u normalnim
            uvjetima, kao i za uzastopne postupke rukovanja. Roba mora biti
            jasno označena kako bi se omogućila trenutna i nedvosmislena
            identifikacija pošiljatelja, primatelja, mjesta isporuke i prirode
            Robe. ROBERT LOGISTIKA j.d.o.o. neće biti odgovorna za posljedice koje su
            nastale ili su u svezi s propustom svake odsutnosti, nedostatka na
            pakiranju, te prirodi odnosno svojstvima Robe.
          </p>
          <p className="data_police">
            Za transportne usluge ukrcaja odnosno iskrcaja Robe, osim ako se TOP
            LOGISTIKA d.o.o. i Korisnik ne dogovore drugačije, rukovanje
            operacijama je u odgovornosti Korisnika odnosno Pošiljatelja, te u
            slučaju nastanka štete nema odgovornosti ROBERT LOGISTIKE j.d.o.o. i/ili
            Prijevoznika.
          </p>
        </div>

        <div>
          <h4>PLAĆANJE</h4>
          <p className="data_police">
            ROBERT LOGISTIKA j.d.o.o. ispostavlja Korisniku račun za obavljene
            Usluge, s rokom dospijeća navedenim na računu. Korisnik je dužan
            podmiriti cjelokupni iznos računa do dana dospijeća navedenog na
            računu.
          </p>
          <p className="data_police">
            Na dospjela, a neplaćena potraživanja od Korisnika, TOP LOGISTIKA
            d.o.o. ima pravo obračunati zakonsku zateznu kamatu.
          </p>
        </div>

        <div>
          <h4>ODGOVORNOST</h4>
          <p className="data_police">
            ROBERT LOGISTIKA j.d.o.o. je dužna u svakoj prilici postupiti kako to
            zahtijevaju interesi Korisnika pažnjom dobroga gospodarstvenika.
          </p>
          <p className="data_police">
            ROBERT LOGISTIKA j.d.o.o. odgovara Korisniku za štetu koja se može
            pripisati njegovoj krivnji ili krivnji njegovih djelatnika. Za štetu
            na robi zbog oštećenja, uništenja ili zakašnjenja u isporuci
            odgovara Prijevoznik koji je izvršio uslugu prijevoza robe i koji je
            robu stvarno preuzeo na prijevoz.
          </p>
          <p className="data_police">
            Za štete uzrokovane krivnjom osoba kojima se ROBERT LOGISTIKA j.d.o.o. u
            svom poslovanju služi ili koje pružaju usluge Korisniku, TOP
            LOGISTIKA d.o.o. odgovara samo za izbor tih osoba, ako su štete u
            uzročnoj vezi s radnjama ili propustima tih osoba (prijevoznika,
            skladištara i slično), ali ne odgovara i za njihov rad, osim ako je
            tu odgovornost izričito preuzela Ugovorom. Međutim TOP LOGISTIKA
            d.o.o. je dužna poduzeti sve potrebne mjere kako bi se osiguralo
            pravo na naknadu štete od odgovorne osobe.
          </p>
          <p className="data_police">
            Za štete iz prethodnog stavka ROBERT LOGISTIKA j.d.o.o. odgovara najviše
            do 150.000,00 kuna ukupno po štetnom događaju.
          </p>
          <p className="data_police">
            ROBERT LOGISTIKA j.d.o.o. ni u kojem slučaju neće biti odgovorna za
            neizravne ili posljedične gubitke, kao što je, ali ne ograničavajući
            se, na gubitak dobiti, gubitak tržišta ili posljedice kašnjenja ili
            odstupanja ma kako nastali.
          </p>
          <p className="data_police">
            Ako ROBERT LOGISTIKA j.d.o.o. obavlja sama i druge poslove obuhvaćene
            nalogom, običajima ili općim uvjetima, ima prava i obveze koje
            proizlaze iz takve vrste poslova.
          </p>
          <p className="data_police">
            ROBERT LOGISTIKA j.d.o.o. ne odgovara za netočno obračunate vozarine i za
            carinske i druge javne pristojbe. ROBERT LOGISTIKA j.d.o.o. je dužna
            podnijeti reklamaciju, odnosno protest samo ako to Korisnik izričito
            traži. Tada je Korisnik dužan predati ROBERT LOGISTICI j.d.o.o. uz
            prijevozne, carinske i druge dokumente potrebne za podnošenje
            reklamacije.
          </p>
          <p className="data_police">
            ROBERT LOGISTIKA j.d.o.o. može, ako je to objektivno moguće, a na zahtjev
            Korisnika, i na trošak, i za račun i uz rizik Korisnika, podnijeti
            odštetni zahtjev i voditi spor protiv osoba s kojima je sklopio
            ugovor u izvršavanju naloga za prijevoz robe.
          </p>
          <p className="data_police">
            Ukoliko ROBERT LOGISTIKA j.d.o.o. obavlja usluge prijevoza njena
            odgovornost je ograničena pod istim uvjetima kao i odgovornost
            prijevoznika u pogledu prijevoza koji mu je povjeren. Ako su
            ograničenja naknade prijevoznika nepoznata ili ako važeći propisi ne
            predviđaju ograničenja, primjenjivat će se najniža ograničenja
            utvrđena u navedenim konvencijama. Zahtjevi su ograničeni na jedan
            zahtjev po pošiljci na kojem će biti izvršeno potpuno i konačno
            podmirivanje svih gubitaka ili štete u vezi s tim:
          </p>
          <ul>
            <li>
              Za cestovni prijevoz: Konvencija o ugovoru o međunarodnom
              cestovnom prijevozu robe potpisana 19.5.1956. („CMR“)
            </li>
            <li>
              Za pomorski prijevoz: Bruxelleska konvencija potpisana 25.08.1924.
              (Haška pravila) i Visbyev protokol od 23.02.1968., A također i
              Protokol 1979 (“Bruxelleska konvencija”)
            </li>
            <li>
              Za zračni prijevoz: Konvencija o objedinjavanju određenih pravila
              za međunarodni zračni prijevoz potpisana 28. svibnja 1999
              („Montrealska konvencija“)
            </li>
            <li>
              Za željeznički prijevoz: Konvencija potpisana 1890. godine u
              Bernu, pravila CIM-a od 09.05.1980., Protokol od 1990. i
              Vilnijuski protokol od 03.06.1999. Ili SMGS za dio željezničkog
              prijevoza koji traje mjesto u zemlji potpisnici SMGS-a
            </li>
            <li>
              Za riječni prijevoz: Konvencija potpisana 22. lipnja 2001. u
              Budimpešti („CMNI“).
            </li>
          </ul>
        </div>

        <div>
          <h4>OSIGURANJE</h4>
          <p className="data_police">
            Isključiva je odgovornost vlasnika robe radi čijeg prijevoza se
            organizira prijevoz robe ili njegovih zastupnika pokrivanje općih
            rizika u slučaju nastanka štete na Robi tijekom prijevoz, a
            sklapanjem police s osiguravajućim društvom po njegovom izboru, osim
            ako je drugačije ugovoreno između Korisnika i ROBERT LOGISTIKE j.d.o.o.
          </p>
        </div>

        <div>
          <h4>SMETNJE PRI REALIZACIJI NARUDŽBE ILI UGOVORA</h4>
          <p className="data_police">
            Korisnici sklapanjem Ugovora prihvaćaju ove Opće uvjete, te
            prihvaćaju da ROBERT LOGISTIKA j.d.o.o. pruža Usluge unutar svojih
            tehničkih i kadrovskih mogućnosti. ROBERT LOGISTIKA j.d.o.o. se obvezuje
            prilikom dogovaranja posla obavijestiti Korisnika o eventualnim
            poznatim poteškoćama pri realizaciji naručenog/ugovorenog posla.
          </p>
        </div>

        <div>
          <h4>KOMUNIKACIJA</h4>
          <p className="data_police">
            Ako nije drugačije navedeno u Ugovoru, sve obavijesti ili druga
            komunikacija između Korisnika i ROBERT LOGISTIKE j.d.o.o. bit će u
            pisanom obliku, a mogu se predati ili dostaviti putem preporučenog
            pisma ili putem elektronske pošte.
          </p>
          <p className="data_police">
            Svi zahtjevi vezani uz promijene, kao i komunikacije ili prigovori
            koji su dani tijekom pružanja usluge relevantni su ako su
            dostavljeni ROBERT LOGISTICI j.d.o.o. pisanim putem na adresu elektronske
            pošte: info@robert-logistika.hr.
          </p>
          <p className="data_police">
            Korisnik je dužan bez odlaganja obavijestiti ROBERT LOGISTIKU j.d.o.o. o
            svakoj promjeni osobnih podataka, podataka za kontakt, podataka o
            statusu pravne osobe, o osobi ovlaštenoj za zastupanje Korisnika.
            Ako Korisnik propusti obavijestiti ROBERT LOGISTIKU j.d.o.o. o promjeni
            adrese prebivališta/sjedišta ili podataka za kontakt, za potrebe
            dokazivanja valjanog pisanog obavještavanja Korisnika o činjenicama
            i okolnostima relevantnim za ispunjavanje obveza TOP LOGISTIKE
            d.o.o. iz Ugovora, smatrat će se valjanima posljednja poznata adresa
            i podaci za kontakt Korisnika odnosno njegova zastupnika. Smatrati
            će se da su poruke TOP LOGISTIKE d.o.o poslane na posljednju TOP
            LOGISTICI d.o.o. poznatu adresu Korisnika, stvarno uredno
            isporučene.
          </p>
        </div>

        <div>
          <h4>PODNOŠENJE I RJEŠAVANJE PRIGOVORA</h4>
          <p className="data_police">
            Pravovremenim prigovorom smatra se onaj koji je poslan u pismenom
            obliku i roku od 7 dana od dana izvršenja usluge. Prigovor mora biti
            u pisanom obliku, ovjeren potpisom i pečatom (ukoliko Korisnik
            raspolaže pečatom) te treba sadržavati jasno obrazložen predmet
            prigovora. Prigovor se dostavlja putem poštanske pošiljke na adresu
            sjedišta ROBERT LOGISTIKA j.d.o.o. ili putem maila na adresu
            info@robert-logistika.hr
          </p>
          <p className="data_police">
            Nepravovremeni i nepotpuni prigovori neće se uzimati u obzir.
            Prigovor mora sadržavati činjenice i dokaze na kojima se temelji, te
            treba biti obrazložen.
          </p>
        </div>

        <div>
          <h4>TAJNOST PODATAKA</h4>
          <p className="data_police">
            Svojom Nalogom ili sklopljenim Ugovorom, Korisnik daje svoju
            izričitu privolu ROBERT LOGISTICI j.d.o.o. da može koristiti, upisivati i
            obrađivati osobne podatke uključujući i OIB za potrebe vlastitih
            evidencija. ROBERT LOGISTIKA j.d.o.o. će prikupljati, obrađivati i čuvati
            osobne i identifikacijske podatke o svojim Korisnicima, te će čuvati
            ove podatke u tajnosti i upotrebljavati će ih samo za vlastite
            potrebe kao i za svrhe koju su u skladu s pozitivnim pravnim
            propisima te ovim Općim uvjetima poslovanja.
          </p>
          <p className="data_police">
            Korisnik nadalje, daje svoju privolu da ROBERT LOGISTIKA j.d.o.o.,
            sukladno Zakonu o zaštiti osobnih podataka, osobne podatke Korisnika
            proslijedi financijskim institucijama, bankama kreditorima odnosno
            agencijama za naplatu potraživanja, društvima registriranim za
            djelatnost provjere kreditne sposobnosti i operatorima usluga s
            dodanom vrijednosti, a u svrhu ispunjenja zakonskih obveza, provjere
            platežne sposobnosti, smanjenja rizika od mogućeg nastanka štete i
            zaštite interesa Korisnika i ROBERT LOGISTIKE j.d.o.o., te umanjenja
            budućeg duga Korisnika.
          </p>
          <p className="data_police">
            Korisnik je suglasan da će ga ROBERT LOGISTIKA j.d.o.o. obavještavati o
            raznim prodajnim akcijama te novim uslugama, kao i o aktualnim
            ponudama proizvoda i usluga. Korisnik može zabraniti takvo
            obavještavanje putem e-maila ili telefona, slanjem pismene
            obavijesti o navedenom putem elektronske pošte:
            info@robert-logistika.hr.
          </p>
        </div>

        <div>
          <h4>RJEŠAVANJE SPOROVA</h4>
          <p className="data_police">
            Sve sporove koji bi mogli proizaći iz zaključenih Ugovora ili ovih
            Općih uvjeta poslovanja ugovorne strane obvezuju su prvo pokušati
            riješiti pregovorima. Za slučaj da stranke spor ne uspiju riješiti
            pregovorima, obvezuju se pokušati ga riješiti mirenjem pri Centru za
            mirenje Hrvatske udruge za mirenje, Zagreb, Teslina 1, u roku od 60
            dana od početka mirenja. Rok od 60 dana može se produljiti suglasnom
            voljom stranaka. Prije okončanja mirenja stranke ne mogu pokrenuti
            sudski / arbitražni postupak. U slučaju da stranke ne sklope nagodbu
            u mirenju njihov spor rješavat će stvarno nadležni sud prema
            sjedištu ROBERT LOGISTIKE j.d.o.o.
          </p>
        </div>

        <div>
          <h4>IZMJENE I DOPUNE OPĆIH UVJETA POSLOVANJA</h4>
          <p className="data_police">
            ROBERT LOGISTIKA j.d.o.o. pridržava pravo izmijeniti ove Opće uvjete
            poslovanja, sukladno važećim propisima.
          </p>
        </div>

        <div>
          <h4>ZAVRŠNE ODREDBE</h4>
          <p className="data_police">
            Ako bilo koja odredba ovih Općih uvjeta poslovanja bude proglašena
            ništavnom to neće imati utjecaja na valjanost ostalih odredbi, te
            sve ostale odredbe ostaju primjenjive.
          </p>
          <p className="data_police">
            Ovi Opći uvjeti poslovanja će se objaviti na Internetskoj stranici
            ROBERT LOGISTIKE j.d.o.o., sukladno važećim propisima u Republici
            Hrvatskoj te će biti javno dostupni.
          </p>
          <p className="data_police">
            Ovi Opći uvjeti poslovanja stupaju na snagu danom objave na
            službenoj internetskoj stranici ROBERT LOGISTIKE j.d.o.o., odnosno
            dana 01.01.2024. godine.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
