import React, { useState } from "react";
import Hamburger from "hamburger-react";
import { Link, useLocation } from "react-router-dom";
import { Nav } from "../Nav/Nav";
import "../../styles/Header.scss";

export function Header() {
  const [isOpen, setOpen] = useState(false);
  const location = useLocation();

  const handleNavToggle = () => {
    setOpen(!isOpen);
    if (!isOpen) {
      document.body.style.overflow = "hidden"; 
    } else {
      document.body.style.overflow = "auto"; 
    }
  };

  const closeNav = () => { 
    setOpen(false);
    document.body.style.overflow = "auto";
  };


  return (
    <div className={`header-container ${isOpen ? "nav-open" : ""}`}>
      <div className="logo" >
      {location.pathname === "/" ? (
          <Link to="/">
            <img src="/assets/logo_w.png" alt="" />
          </Link>
        ) : (
          <Link to="/">
            <img src="/assets/logo.png" alt="" />
          </Link>
        )}
      </div>
      <Hamburger
        toggled={isOpen}
        toggle={handleNavToggle}
        className="hamburger-react"
      />
      {isOpen && <Nav closeNav={closeNav} />}
    </div>
  );
}
