import { Footer } from "../layouts/Footer/Footer";
import { Header } from "../layouts/Header/Header";
import "../styles/PrivacyPolice.scss";

export function PrivacyPolice() {
  return (
    <>
      <div className="container">
        <Header />
        <div className="police-content">
          <h1>POLITIKA PRIVATNOSTI</h1>
          <div>
            <p>
              Ova pravila propisuju kako Robert Logistika j.d.o.o. koristi i
              štiti bilo koje podatke koje nam pružate prilikom korištenja ove
              web stranice. Predano radimo na osiguravanju zaštite vaše
              privatnosti.
            </p>
          </div>
          <div>
            <h2>Prikupljanje informacija</h2>
            <p>
              Robert Logistika j.d.o.o. upravlja robert-logistika.hr web
              stranicom. Ova stranica vas informira u vezi prikupljanja,
              korištenja i prikaza osobnih podataka koje primamo od korisnika
              ove stranice. Možemo vas zatražiti i prikupljati sljedeće podatke
              putem “Zatražite ponudu” i “Kontaktirajte nas” obrazaca i putem
              analitičkih alata.
            </p>
          </div>

          <div>
            <h4>Osobni podatci:</h4>
            <ul className="data_police">
              <li>Osobno ime i ime tvrtke</li>
              <li>Kontakt podatci uključujući e-mail i web adresu</li>
              <li>
                Ostali podatci od važnosti za ponudu klijentu: tip projekta,
                budžet, rok završetka i drugi detalji
              </li>
              <li>
                Također možemo zadržati bilo koje podatke kroz eventualne poruke
                koje nam pošaljete putem web stranice
              </li>
              <li>
                Moguće je da ćemo koristiti vaše podatke kako bismo vas
                kontaktirali s newsletterima, marketing ili promotivnim
                materijalima i drugim informacijama
              </li>
            </ul>
          </div>

          <div>
            <h4>Analitički podatci:</h4>
            <ul className="data_police">
              <li>
                Demografski podatci kao što su poštanski broj, želje i interesi
                koje prikupljamo u trenutku prihvaćanja kolačića
              </li>
              <li>
                Možemo direktno skupljati analitičke podatke, ili koristiti
                analitičke alate ili usluge trećih strana, kako bi nam pomogli
                mjeriti promet i trendove korištenja stranice. Ovi alati
                prikupljaju podatke koje nam šalje vaš preglednik ili mobilni
                uređaj, uključujući koje stranice posjećujete i druge
                informacije koje nam pomažu u unaprjeđenju stranice.
              </li>
            </ul>
          </div>
          <div>
            <h2>Korištenje osobnih podataka</h2>
            <div>
              <p>
                Koristimo te informacije za rad, održavanje ove web stranice.
                Također ih koristimo za pružanje boljih značajki i
                funkcionalnosti ove web stranice. Osim toga, koristimo
                pojedinosti vašeg upita i naše ponude da bismo razumjeli vaše
                potrebe i ponudili vam odličnu uslugu.
              </p>
            </div>

            <div>
              <h4>Dodatno:</h4>
              <ul className="data_police">
                <li>Možemo ih koristiti za internu evidenciju podataka</li>
                <li>
                  Možemo koristiti podatke kako bismo unaprijedili naše
                  proizvode i usluge
                </li>
                <li>
                  Možemo koristiti podatke kako bismo vam ponudili naše
                  proizvode i usluge
                </li>
                <li>
                  Povremeno, možemo koristiti vaše podatke kako bismo vas
                  kontaktirali putem e-maila, telefona, faksa ili pošte
                </li>
                <li>Možemo koristiti podatke kako bismo poboljšali stranicu</li>
              </ul>
              <div>
                <p>
                  Koristimo ove osobne podatke sukladno vašim interesima na
                  temelju i u skladu s privolom koju nam dajete prilikom
                  korištenja kontakt obrasca na ovoj web stranici
                </p>
              </div>
            </div>

            <div>
              <h2>Korištenje „kolačića“ i analitike</h2>
              <p>
                Kada posjetite našu web stranicu, možemo poslati jedan ili više
                „kolačića“, malih tekstualnih datoteka koje sadrže niz
                alfanumeričkih znakova, na vaše računalo koje jedinstveno
                identificira vaš preglednik i oni vam pomažu u navigaciji kroz
                web stranicu. „Kolačić“ nam također može prenijeti podatke o
                načinu korištenja web stranice ( npr. stranice koje gledate,
                poveznice na koje klikate i ostale aktivnosti koje poduzimate na
                web stranici ), i omogućiti nam da povremeno pratimo vaše
                korištenje web stranice. „Trajni kolačić“ ostaje na vašem tvrdom
                disku nakon što zatvorite preglednik. Vaš preglednik može
                koristiti „trajne kolačiće“ prilikom ponovnih posjeta web
                stranici. „Trajni kolačić“ može biti uklonjen slijeđenjem
                uputstava vašeg preglednika. „Sesijski kolačić“ je privremen i
                nestaje nakon što zatvorite preglednik.
                <p>
                  Možete postaviti svoj preglednik da blokira sve "kolačiće" ili
                  možete prilagoditi "kolačiće" u postavkama. Želimo napomenuti
                  da određene funkcije web stranice neće biti dostupne ako je
                  onemogućeno prihvaćanje "kolačića". Koristimo prikupljene
                  podatke, kao što je Vaša adresa e-pošte, tako da možemo
                  izravno stupiti u kontakt s Vama u slučaju da ste dali
                  pristanak prilikom korištenja obrasca za kontakt na ovoj web
                  stranici.
                </p>
                <p>
                  „Kolačiće“ koristimo u skladu s dozvolom koju ste nam putem
                  postavki „kolačića.
                </p>
              </p>
            </div>

            <div>
              <h2>Kontrola vaših analitičkih podataka</h2>
              <p>
                Možete postaviti vaš preglednik da odbija sve „kolačiće“ ili
                možete koristiti postavke za „kolačiće“ kako biste detaljnije
                prilagodili. Napominjemo da neke od funkcionalnosti web stranice
                neće biti dostupne ako je mogućnost prihvata „kolačića“
                onemogućena.
              </p>
              <p>
                Koristimo podatke koje prikupljamo ili primamo, kao što su vaša
                email adresa, kako bismo direktno komunicirali s vama ili vas
                kontaktirali putem newslettera, marketing i promotivnih
                materijala i drugih informacija samo u slučaju da ste nam za to
                dali privolu korištenjem formi na ovoj web stranici.
              </p>
            </div>

            <div>
              <h2>Kontrola vaših osobnih podataka</h2>
              <p>
                Ako ste se prethodno složili da koristimo vaše podatke u svrhu
                direktnog marketinga, možete se predomisliti u svakom trenutku,
                i poslati nam zahtjev pisanim putem na adresu Omladinska ulica
                119, 31431 Livana ili putem elektronske pošte na
                info@robert-logistika.hr Nećemo prodavati, distribuirati ili
                unajmljivati ​​vaše osobne podatke trećim stranama sukladno
                odredbama Uredbe (EU) 2016/679 ( Opća uredba o zaštiti podataka,
                skraćeno: GDPR) bez vašeg dopuštenja ili ako to zakon zahtijeva.
                Vaše ćemo osobne podatke koristiti za slanje promotivnih
                informacija o trećim stranama samo ako imamo vaš pristanak.
              </p>
              <p>
                Ako vjerujete da bilo koji podatak koji posjedujemo je netočan
                ili nepotpun, molim vas da nas kontaktirate. Također imate
                pravo, u skladu s odredbom GDPR, zatražiti brisanje vaših
                osobnih podataka, kao i ograničiti naše korištenje vaših osobnih
                podataka.
              </p>
            </div>

            <div>
              <h2>Službenik za zaštitu podataka</h2>
              <p>
                Robert Logistika j.d.o.o. je imenovao službenika za zaštitu
                podataka, g. Roberta Brkića, kojeg možete kontaktirati putem
                sljedećeg maila info@robert-logistika.hr Također nas možete
                kontaktirati putem kontakt formi ili običnom poštom.
              </p>
              <p>
                Ako smatrate da se vaši podatci ne obrađuju u skladu s
                postojećim propisima također možete kontaktirati nadzorno tijelo
                nadležno za nadzor primjene GDPR uredbe u Republici Hrvatskoj.
              </p>
            </div>

            <div>
              <h2>Sigurnost</h2>
              <p>
                Kako bismo spriječili neovlašten pristup ili odavanje osobnih
                podataka, primjenjujemo prikladne fizičke, elektroničke i
                poslovne procedure u svrhu zaštite i osiguranja podataka koje
                prikupljamo online.
              </p>
            </div>

            <div>
              <h2>Poveznice na druge web stranice</h2>
              <p>
                Naša web stranica može sadržavati poveznice na druge web
                stranice. Molimo vas da uzmete u obzir da kada napustite naše
                stranice više nemamo kontrolu i nismo odgovorni za zaštitu
                privatnosti ili drugih informacija koje su predstavljene na tim
                stranicama. Molimo vas da provjerite izjave o privatnosti tih
                stranica.
              </p>
            </div>

            <div>
              <h2>Izmjene</h2>
              <p>
                Ova pravila o zaštiti privatnosti su posljednji put ažurirana 1.
                ožujka 2023. Zadržavamo pravo da ih ažuriramo ili promijenimo.
                Za daljnja pitanja, molimo vas da nas kontaktirate.
              </p>
            </div>
          </div>
        </div>
        <div className="footer">
          <Footer />
        </div>
      </div>
    </>
  );
}
