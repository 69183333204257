import { partners } from "../Services/partners";
import "../styles/Partners.scss";
import { ScrollAnimation } from "../components/ScrollAnimation/ScrollAnimation";


export function Partners() {
  return (
    <div className="partners-container">
      <div className="partners-title">
        <h1 className="title">Naši Partneri</h1>
        <p className="subtitle">Naši partneri u poslovanju - Gradimo dugoročne odnose temeljene na povjerenju</p>
      </div>

      <ScrollAnimation>
      <div className="partners-main">
        <div className="partners-list">
          {partners.map((partner) => (
            <div className="partner-box" key={partner.id}>
              <div className="partners-image">
                <img src={partner.img} alt={partner.title} />
              </div>

              <div className="partners-name">
                <p className="partner-name">{partner.title}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      </ScrollAnimation>
    </div>
  );
}
