import { Button } from "../ui/Button";
import "../styles/TransportService.scss";
import { ScrollAnimation } from "../components/ScrollAnimation/ScrollAnimation";

export function TransportService() {
  const handleCallClickMobile = () => {
    window.location.href = "tel:+385915151011";
  };

  return (
    <div className="service-container">
      <div className="title-main">
        <h1 className="transport-title">Naše usluge</h1>
      </div>
      <ScrollAnimation>
        <div className="transport-content">
          <div className="transport-text">
            <p className="above-title">CIJELA DRŽAVA</p>
            <h2 className="service-title">Dostava iz trgovačkih centara</h2>
            <p className="description">
              Kombi dostava odnosi se na uslugu transporta koja se obavlja kombi
              vozilima. Ova vrsta dostave često se koristi za prijevoz manjih
              paketa, namještaja ili drugih tereta koji ne stanu u standardni
              osobni automobil. Kombi dostava može biti pogodna za privatne
              osobe, ali i za poslovne korisnike koji trebaju prijevoz robe ili
              materijala.
            </p>
            <div className="transport-button">
              <Button
                type="button"
                className="btn-contact"
                name="Kontaktirajte nas"
                onClick={handleCallClickMobile}
              />
            </div>
          </div>
          <div className="delivery-image">
            <img
              src="/assets/services/utovar.jpg"
              alt="utovar"
              className="img1"
            />
            <img
              src="/assets/services/paleta.jpg"
              alt="paleta"
              className="img2"
            />
            <img
              src="/assets/services/paketi.jpg"
              alt="paket"
              className="img3"
            />
          </div>
        </div>
      </ScrollAnimation>
      <ScrollAnimation>
        <div>
          <div className="international-content">
            <div className="transport-image">
              <img
                src="/assets/dostava.png"
                alt="route"
                className="route-image"
              />
            </div>
            <div className="delivery-text">
              <p className="above-title">BILO KOJE ODREDIŠTE</p>
              <h2 className="service-title">Dostava paketa</h2>
              <p className="description">
                Paketna dostava od vrata do vrata! Usluga prikupa paketa svaki
                dan do 12:00. Istodnevna isporuka poslovne dokumentacije,
                paletiziranih i ostalih pošiljaka.
              </p>
              <div className="transport-button">
                <Button
                  type="button"
                  className="btn-contact"
                  name="Kontaktirajte nas"
                  onClick={handleCallClickMobile}
                />
              </div>
            </div>
          </div>
        </div>
      </ScrollAnimation>
    </div>
  );
}
