
import { Header } from "../layouts/Header/Header";
import { Button } from "../ui/Button";
import "../styles/HomeSection.scss";
import { AnimateText } from "../components/AnimateText/AnimateText";

export function HomeSection() {

  const handleCallClickMobile = () => {
    window.location.href = "tel:+385915151011";
  };

  return (
    <div className="home-container">
      <Header />
      <div className="header-content">
        <div className="header-title">
          <AnimateText />
        </div>
        <div className="header-button">
          <Button type="button" className="contact-button" name="Kontaktirajte nas" onClick={handleCallClickMobile} />
        </div>
        <div className="image-content">
          <img src="assets/Combi.png" alt="combi" />
        </div>
      </div>
    </div>
  );
}
