import { Header } from "../layouts/Header/Header";
import { Footer } from "../layouts/Footer/Footer";
import "../styles/CookiesPage.scss";

export function CookiesPage() {
  return (
    <div className="container">
      <Header />
      <div className="cookies-content">
        <h1>Kolačići</h1>
        <div>
          <p>Cijenimo vaše podatke i o njima se odgovorno brinemo.</p>
          <p>
            Kako bi posjet vašoj stranici bio funkcionalniji i praktičniji, naše
            stranice spremaju određenu količinu informacija na vaše računalo –
            kolačiće. Oni služe za optimizaciju web stranica i poboljšavaju vaše
            iskustvo pregledavanja i korištenja. Kolačiće koristimo, između
            ostalog, kako bismo identificirali posjetitelje i odredili kako se
            stranice koriste. Kolačići sadrže informacije o vašim
            preferencijama, ponašanju i izborima tijekom korištenja naše
            stranice. Posjetom i korištenjem ove stranice pristajete na
            korištenje kolačića. Kolačići se mogu blokirati. Nakon blokiranja
            kolačića i dalje ćete moći pregledavati stranice, ali neke značajke
            možda neće biti dostupne ili neće biti u potpunosti funkcionalne.
          </p>
        </div>
        <div>
          <h2>Što su kolačići?</h2>
          <p className="data_police">
            Kolačić je datoteka informacija koju pohranjuju web stranice na
            vašem računalu, mobilnom telefonu ili tabletu, a koje može spremiti
            web stranica koju posjećujete (kolačići prve strane) ili u suradnji
            i za potrebe web stranica trećih strana (kolačići trećih strana ).
            Kolačići općenito spremaju vaše postavke, postavke za web stranicu,
            olakšavajući vam korištenje stranice i prikaz relevantnog sadržaja.
            Nakon što ponovno otvorite web stranicu, vaš preglednik šalje natrag
            kolačiće koji pripadaju ovoj stranici. To omogućuje stranici da
            prikaže informacije prilagođene vašim potrebama. Kolačići mogu imati
            širok raspon informacija, uključujući neke osobne podatke. Takve
            informacije mogu se spremiti samo ako to omogućite. Sama stranica ne
            može pristupiti informacijama koje niste dali, a kolačići ne mogu
            pristupiti bilo kojoj drugoj datoteci na vašem računalu.
          </p>
        </div>

        <div>
          <h4>Kako blokirati kolačiće?</h4>
          <p className="data_police">
            Možete blokirati pohranjivanje kolačića u vašem internetskom
            pregledniku. Blokiranje kolačića može imati negativan učinak na vaše
            korištenje web stranice. Za blokiranje kolačića morate postaviti
            odgovarajuće postavke u svom pregledniku. Odaberite pomoć iz
            izbornika preglednika i pronađite informacije o tome kako blokirati
            kolačiće te slijedite njihove upute.
          </p>
        </div>

        <div>
          <h4>Neophodni kolačići</h4>
          <p className="data_police">
            Neophodni kolačići čine stranicu upotrebljivom omogućujući osnovne
            značajke kao što su navigacija stranicama i pristup zaštićenim
            područjima. Web stranica ne može pravilno funkcionirati bez ovih
            kolačića, a nastavkom korištenja stranice ili aplikacije pristajete
            na njihovo učitavanje na svoj uređaj.
          </p>
        </div>
        <div>
          <div>
            <h4>Privremeni kolačići</h4>
            <p className="data_police">
              Privremeni kolačići sesije ili kolačići uklanjaju se s vašeg
              računala nakon što zatvorite internetski preglednik. Privremeni
              kolačići pohranjuju privremene podatke koji nisu nužni za
              korištenje stranice po dolasku.
            </p>
          </div>

          <div>
            <h4>Trajni kolačići</h4>
            <p className="data_police">
              Trajni ili spremljeni kolačići ostaju na vašem računalu čak i
              nakon što zatvorite program internetskog preglednika. Trajni
              kolačići osiguravaju ispravan i optimalan rad stranica, prate vaše
              podatke za prijavu, spremaju postavke prijave, optimiziraju
              preglede stranica i optimizaciju u stvarnom vremenu.
            </p>
          </div>

          <div>
            <h4>Kolačići treće strane</h4>
            <p className="data_police">
              Kolačiće treće strane postavljaju druge web stranice čije usluge
              koristimo. Ovi kolačići postavljeni su za normalno funkcioniranje
              određenih značajki koje korisnicima olakšavaju pristup sadržaju.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
