import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HomePage } from "./pages/HomePage";
import { PrivacyPolice } from "./pages/PrivacyPolice";
import { TermsAndConditions } from "./pages/TermsAndConditions";
import { CookiesPage } from "./pages/CookiesPage";
import { Cookies } from "./layouts/Cookie/Cookies";
import './App.scss';

export function App() {
  return (
    <div className="App">
      <Cookies />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/terms" element={<TermsAndConditions />}/>
          <Route path="/privacy-policy" element={<PrivacyPolice />} />
          <Route path="/cookies" element={<CookiesPage />}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}


